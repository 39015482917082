export const operatingModes = [
  "CW",
  "PIXIE_CW",
  "WSPR",
  "FT8",
  "FT4",
  "FSQ_2",
  "FSQ_3",
  "FSQ_4_5",
  "FSQ_6",
  "JT9",
  "JT65",
  "JT4",
];
